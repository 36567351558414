html{
  background-color: whitesmoke;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: whitesmoke;
}

.edit {
  cursor: pointer;
}

.logo {
  width: 210px;
}

.error {
  color: red;
}

.table {
  text-align: justify;
  margin-left: 245px;
  width: -webkit-fill-available;
  margin-right: 5px;
}
